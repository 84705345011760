<template>
  <div class="component-base-stats">
    <div class="businessline-wrap">
      <div class="legend-conf">
        <div class="conf-L">
          <el-date-picker
            format="YYYY-MM-DD HH:mm"
            v-model="refBetweenTime"
            type="datetimerange"
            :shortcuts="shortcuts"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <span>&nbsp;&nbsp;</span>
          <el-radio-group v-model="refTimeScale">
            <el-radio label="day">日</el-radio>
            <el-radio label="week">周</el-radio>
            <el-radio label="month">月</el-radio>
          </el-radio-group>
        </div>
        <div class="conf-R">
          <el-checkbox-group v-model="refPlatformList">
            <el-checkbox v-for="(val, key) in platFormMap" :key="key" :label="key">{{ val }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div style="width: 100%" class="charts-body" :id="chartId"></div>
      <div style="text-align: center">
        <el-checkbox-group style="margin-top: 12px" v-model="refCategoryList">
          <el-checkbox v-for="op in categoryOps" :key="op.id" :label="op.id">{{ op.name }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, watch, PropType } from 'vue'
import { format } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import { fetchBusinessStats, fetchPlatformStats } from '@/domains/stats/api'
import type { StatData } from '@/domains/stats/models/stats'
import { shortcuts, defaultBetweenTime, TimeScale, useHandleChartsInit, calcxAxis } from '@/views/stats/constant'

const defaultChartData = {
  title: {
    text: ''
  },
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  toolbox: {
    feature: {
      // saveAsImage: {},
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: []
}
const businessTypeMap = {
  hangqing: '行情',
  yunying: '运营',
  zengzhi: '增值',
  celue: '策略'
}
const platFormMap: { [key: string]: string } = {
  total: '合计',
  ios: 'ios',
  android: 'android',
  mweb: 'm站',
  web: 'pc站'
}

const categoryMap: { [key: number]: string } = {
  1: '新增',
  2: '活跃',
  3: '老用户',
  4: '回流用户',
  5: '未注册',
  6: '注册率',
  7: '留存率',
  8: '总用户'
}

export default defineComponent({
  name: 'BaseStats',
  components: {},
  props: {
    targetType: { type: String, default: 'business' },
    path: { type: String, default: '' },
    categoryOps: {
      type: Object as PropType<{ id: number; name: string }[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const chartId = `XGB-${Math.random().toString(36).slice(6).toUpperCase()}`
    const fetchMethod = props.targetType === 'business' ? fetchBusinessStats : fetchPlatformStats
    const defaultCate = [props.categoryOps[0].id]
    const chartIns = useHandleChartsInit(`#${chartId}`, 'dark')
    const refBetweenTime = ref<Date[]>(defaultBetweenTime)
    const refPlatformList = ref<string[]>(['total'])
    const refCategoryList = ref<number[]>(defaultCate)
    const refTimeScale = ref<TimeScale>('day')
    const renderChartData = cloneDeep(defaultChartData)
    // 线条缓存池
    let refCacheLines: any[] = []

    const clearCacheLine = () => {
      refCacheLines = []
    }

    // 当平台更新后去拉取API填充refCacheLines
    const updateStatsCacheByPlatform = async (platform = 'total') => {
      const [start, end] = refBetweenTime.value
      const params = {
        period: refTimeScale.value,
        platform: platform !== 'total' ? [platform] : ['ios', 'android', 'web', 'mweb'],
        start: Math.round(start.getTime() / 1000),
        end: Math.round(end.getTime() / 1000),
        path: props.targetType === 'platform' ? '' : props.path
      }
      const apiQueue: Promise<any>[] = []
      refCategoryList.value.forEach((cate) => {
        // 如果cacheid匹配则不请求
        if (refCacheLines.find((line) => line.cacheid === `${platform}${cate}`)) return
        const api = fetchMethod({ ...params, category: cate }).then((res: StatData) => {
          if (res.items && res.items.length) {
            res.items.sort((L, R) => L.timestamp - R.timestamp)
            const lines = res.items.map((item) => {
              const axis_text = format(item.timestamp * 1000, 'MM-dd')
              const axis_val = item.field_type === 'int' ? item.int_val || 0 : Number((item.double_val || 0).toFixed(1))
              return [axis_text, axis_val]
            })
            refCacheLines.push({
              name: `${platFormMap[platform]}-${categoryMap[cate]}`,
              cacheid: `${platform}${cate}`,
              expires: Date.now() + 600 * 1000,
              type: 'line',
              data: lines
            })
          }
        })
        apiQueue.push(api)
      })
      if (apiQueue.length) {
        await Promise.all(apiQueue)
        return
      }
      return
    }

    // 渲染操作 从缓存池取线条
    const updateStatsFromCache = (plats: string[], cates: number[]) => {
      const cacheIdMap: { [key: string]: boolean } = {}
      plats.forEach((plat) => {
        cates.forEach((cate) => {
          cacheIdMap[`${plat}${cate}`] = true
        })
      })
      const series = refCacheLines.filter((line) => {
        return cacheIdMap[line.cacheid] === true
      })
      const isAdd = series.length > renderChartData.series.length
      renderChartData.series = series as never[]
      renderChartData.xAxis.data = calcxAxis(series) as never[]
      chartIns.value.setOption(cloneDeep(renderChartData), !isAdd)
    }

    // 当数据类型发生变化批量更新当前选中平台
    const updateAsyncByPlatCate = () => {
      const updateQueue: Promise<any>[] = []
      refPlatformList.value.forEach((plat) => {
        updateQueue.push(updateStatsCacheByPlatform(plat))
      })
      Promise.all(updateQueue)
        .then(() => {
          updateStatsFromCache(refPlatformList.value, refCategoryList.value)
        })
        .catch((err) => {
          console.log('watch存在API拉取失败')
          updateStatsFromCache(refPlatformList.value, refCategoryList.value)
        })
    }

    onMounted(async () => {
      try {
        await updateStatsCacheByPlatform('total')
        updateStatsFromCache(['total'], defaultCate)
      } catch (error) {
        console.log('onMounted-存在API拉取失败')
        updateStatsFromCache(['total'], defaultCate)
      }
    })

    watch([refPlatformList, refCategoryList], ([newPlats, newCates], [oldPlats, oldCates]) => {
      if ((newPlats as []).length === 0 || (newCates as []).length === 0) {
        renderChartData.series = []
        chartIns.value.setOption(cloneDeep(renderChartData), true)
        return
      }
      updateAsyncByPlatCate()
    })

    watch([refBetweenTime, refTimeScale], () => {
      clearCacheLine()
      updateAsyncByPlatCate()
    })

    return {
      chartId,
      platFormMap,
      businessTypeMap,
      shortcuts,
      // reactive vars
      refBetweenTime,
      refPlatformList,
      refCategoryList,
      refTimeScale
    }
  }
})
</script>
<style lang="scss">
.component-base-stats {
  position: relative;
  .legend-conf {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    .conf-R {
      margin-left: 10px;
    }
  }
  .charts-body {
    min-height: 500px;
    width: 100%;
  }
}
</style>
